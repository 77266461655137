import { EventCard, Header, Image } from "./Event";

type Props = {};

function StayTuned(props: Props) {
  return (
    <EventCard>
      <Header>
        <h3>Subscribe to get updates for upcoming events!</h3>
      </Header>
      <Image src={"/images/stay-tuned.png"} />
    </EventCard>
  );
}

export default StayTuned;
