import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AddEvent from "./addEvent";
import Home from "./home/home";
import PastEvents from "./pastEvents/pastEvents";
import UpcomingEvents from "./upcomingEvents/UpcomingEvents";
import Login from "./login/Login";
import AdminEvents from "./adminEvents";
import AdminSubscribers from "./adminSubscribers";
import AdminBanners from "./adminBanners";
import AddBanner from "./addBanner";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/upcoming-events",
    element: <UpcomingEvents />,
  },
  {
    path: "/past-events",
    element: <PastEvents />,
  },
  {
    path: "/admin",
    element: <AddEvent />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/admin-events",
    element: <AdminEvents />,
  },
  {
    path: "/admin-subscribers",
    element: <AdminSubscribers />,
  },
  {
    path: "/admin-banners",
    element: <AdminBanners />,
  },
  {
    path: "/add-banners",
    element: <AddBanner />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache(),
  credentials: "include",
});

root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
