import { useState } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import SubscriptionBar from "./SubscriptionBar";
import { SubscribeIcon } from "../icons";

function TopMenu() {
  const location = useLocation();
  const [isSubscriptionBarOpen, setIsSubscriptionBarOpen] = useState(false);
  const handleToggleSubscriptionBar = () => {
    setIsSubscriptionBarOpen(!isSubscriptionBarOpen);
  };

  return (
    <>
      <Header>
        <LinkStyled to="/">
          <LogoWrapper>
            <img src="/images/BWFBB.png" alt="Blue Friday" height="28px" />
          </LogoWrapper>
          <Subheader>Maritime Tech Talk</Subheader>
        </LinkStyled>
        <MainMenuWrapper>
          <MenuItem current={location.pathname === "/upcoming-events"}>
            <LinkStyled to="/upcoming-events">Upcoming Events</LinkStyled>
          </MenuItem>
          <MenuItem current={location.pathname === "/past-events"}>
            <LinkStyled to="/past-events">Past Events</LinkStyled>
          </MenuItem>
          <MenuItem current={true}>
            <LinkStyled to="" onClick={handleToggleSubscriptionBar}>
              Subscribe
            </LinkStyled>
          </MenuItem>
        </MainMenuWrapper>
        <MainMenuWrapperMobile>
          <MenuItem current={location.pathname === "/upcoming-events"}>
            <LinkStyled to="/upcoming-events">Next</LinkStyled>
          </MenuItem>
          <MenuItem current={location.pathname === "/past-events"}>
            <LinkStyled to="/past-events">Past</LinkStyled>
          </MenuItem>
          <MenuItem current={true}>
            <LinkStyled to="" onClick={handleToggleSubscriptionBar}>
              <SubscribeIcon size={20} />
            </LinkStyled>
          </MenuItem>
        </MainMenuWrapperMobile>
      </Header>
      <SubscriptionBar isOpen={isSubscriptionBarOpen}></SubscriptionBar>
    </>
  );
}

const Header = styled.header`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 50px;
  background-color: #0081c7;
  position: sticky;
  top: 0;

  @media (max-width: 550px) {
    padding: 0 5px;
  }
`;

const Subheader = styled.div`
  position: absolute;
  top: 15px;
  height: 50px;
  color: white;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 800;
  align-items: center;
`;

const MainMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  @media (max-width: 550px) {
    display: none;
  }
`;

const MainMenuWrapperMobile = styled(MainMenuWrapper)`
  display: none;
  @media (max-width: 550px) {
    display: flex;
  }
`;

const LogoWrapper = styled.div`
  margin-bottom: 12px;
`;

const MenuItemParent = styled.div`
  cursor: pointer;
  position: relative;
  line-height: 50px;
  ::after {
    position: absolute;
    inset-inline: 16px;
    bottom: 0;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: "";
    width: 100%;
    left: 0;
  }
  &:hover {
    ::after {
      border-bottom: 2px solid white;
      @media (max-width: 550px) {
        border-bottom: 2px solid transparent;
      }
    }
  }
`;

const MenuItem = styled(MenuItemParent)<{ current: boolean }>`
  margin-left: 25px;
  font-weight: ${(props) => (props.current ? 800 : 500)};

  @media (max-width: 550px) {
    margin: 0 8px;
  }
`;

const LinkStyled = styled(Link)`
  height: 50px;
  align-items: center;
  text-decoration: none;
  color: white;
  display: flex;
  &:visited {
    color: white;
    text-decoration: none;
  }
`;

export default TopMenu;
