import styled from "styled-components";
import { ApolloError } from "@apollo/client/errors";
import moment from "moment";

type Props = {
  loading: boolean;
  error?: ApolloError;
  data: any;
  isLink: boolean;
};

function Event(props: Props) {
  if (props.loading) return <p>Loading...</p>;
  if (props.error) return <p>Error : {props.error.message}</p>;

  return props.data.nextevent.map(
    ({
      id,
      title,
      pic,
      date,
    }: {
      id: string;
      title: string;
      pic: string;
      date: string;
    }) => {
      let eventDate = new Date(date);
      let outputDate = moment(eventDate).format("DD MMM YYYY");

      return (
        <EventCard key={id}>
          <Header>
            <h3>{title}</h3>
            <div>{outputDate}</div>
          </Header>
          <Content>
            {props.isLink ? (
              <a
                href="https://uni-rostock-de.zoom.us/j/69269181012?pwd=WHF2WWVKZWtWbDV3ZFhKVC95ejBSZz09"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={pic} />
              </a>
            ) : (
              <Image src={pic} />
            )}
          </Content>
        </EventCard>
      );
    }
  );
}

export const Image = styled.img`
  width: 100%;
`;

export const EventCard = styled.div`
  background: #ffffff;
  padding: 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-top: 10px;
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 1px;
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
  //border-bottom: 1px solid #e3e3e3;
`;

const Content = styled.div``;

export default Event;
