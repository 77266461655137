import styled, { keyframes } from "styled-components";
import type { Banner as BannerType } from "../types";

function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

type Props = {
  banners: BannerType[];
};

function BannersCarousel(props: Props) {
  const banners = shuffle(props.banners);
  const displayBanners = [...banners, ...banners].map((banner, index) => ({
    ...banner,
    id: `${banner.id}${index}`,
  }));

  return (
    <Wrapper>
      <Container>
        {displayBanners.map((banner) => {
          return (
            <Banner key={banner.id}>
              <a href={banner.link} target="_blank" rel="noopener noreferrer">
                <Image src={banner.pic} alt={banner.company} />
              </a>
            </Banner>
          );
        })}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
  max-width: 960px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const slide = keyframes`
  from { transform: translateX(0px); }
  to { transform: translateX(-960px); }
`;

const Banner = styled.div`
  margin-right: 20px;
  animation-name: ${slide};
  animation-iteration-count: infinite;
  animation-duration: 30s;
  animation-timing-function: linear;
`;

const Image = styled.img`
  width: 100px;
`;

export default BannersCarousel;
