import React from "react";
import Layout from "../components/Layout";
import EventsList from "../components/EventsList";
import { useQuery, gql } from "@apollo/client";

const GET_EVENTS = gql`
  query {
    events(timeDirection: PAST) {
      id
      title
      pic
      date
    }
  }
`;

function PastEvents() {
  const { loading, error, data } = useQuery(GET_EVENTS, {fetchPolicy:"no-cache"});

  return (
    <Layout>
      <EventsList loading={loading} error={error} data={data} isLink={false} />
    </Layout>
  );
}

export default PastEvents;
