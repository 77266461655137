import React from "react";
import Layout from "../components/Layout";
import Event from "../components/Event";
import { useQuery, gql } from "@apollo/client";
import styled from "styled-components";
import BannersCarousel from "../components/BannersCarousel";
import StayTuned from "../components/StayTuned";

const GET_NEXTEVENTS = gql`
  query {
    nextevent {
      id
      title
      date
      pic
    }
  }
`;

const GET_BANNERS = gql`
  query {
    banners {
      id
      company
      pic
      link
    }
  }
`;

function Home() {
  const {
    loading: eventLoading,
    error: eventError,
    data: eventData,
  } = useQuery(GET_NEXTEVENTS, {
    fetchPolicy: "no-cache",
  });

  const { data: bannerData } = useQuery(GET_BANNERS, {
    fetchPolicy: "no-cache",
  });

  if (eventData && bannerData) {
    return (
      <Layout>
        <h2>What is Blue Friday?</h2>
        <p>
          Blue Friday - Maritime Tech Talk aims to bring people together from
          industry and academics to share and exchange recent advancements in
          the sectors related to the maritime technology.
        </p>
        <p>
          The tech talks take place always on friday 13:00 CET and will be held
          online on a irregular base. Dates will be announced in prior.{" "}
        </p>
        <p>
          Interested? Just subscribe for updates. Don't worry, we won't spam
          your E-Mail folder, just inform you when new topics and date are
          disclosed. Everybody is welcome to attend.
        </p>
        {eventData.nextevent.length > 0 ? (
          <>
            <h3>Next Event:</h3>
            <Event
              loading={eventLoading}
              error={eventError}
              data={eventData}
              isLink={false}
            />
            <h4>
              <center>
                It's friday 13:00 CET?
                <a
                  href="https://uni-rostock-de.zoom.us/j/69269181012?pwd=WHF2WWVKZWtWbDV3ZFhKVC95ejBSZz09"
                  target="_blank"
                  rel="noreferrer"
                >
                  <JoinButton>join via zoom</JoinButton>
                </a>
              </center>
            </h4>
          </>
        ) : (
          <StayTuned />
        )}
        <h3>Supported by:</h3>
        <BannersCarousel banners={bannerData?.banners || []} />
        <h3>BlueFriday is a cooperation of:</h3>
        <BannerBlock>
          <Image src={"/images/tub.jpeg"} alt="banner TUB" width={10} />
          <Image src={"/images/ude.jpeg"} alt="banner UDE" width={15} />
          <Image src={"/images/tuhh.jpeg"} alt="banner TUHH" width={15} />
          <Image src={"/images/uro.png"} alt="banner URO" width={20} />
        </BannerBlock>
      </Layout>
    );
  } else {
    return null;
  }
}

const BannerBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img<{ width: number }>`
  width: ${(props) => props.width}%;
  margin-right: 30px;
`;

const JoinButton = styled.div`
  background-color: rgb(222, 222, 222);
  border: none;
  color: black;
  padding: 4px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
`;

export default Home;
